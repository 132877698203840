<template>

  <v-main>

    <v-container
        id="error-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-alert-octagon-outline"
          class="px-5 py-3"
      >


        <v-card-text>

          <v-alert color="error"
                   outlined
                   dark
                   show variant="danger">

            <v-row>
              <v-col
                  cols="12"
                  sm="3"
              >
                Error Code:
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                {{ errorCode }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="3"
              >
                Error Message:
              </v-col>
              <v-col
                  cols="12"
                  sm="8"
              >

                    <span v-if="errorMap[errorCode]">
                      {{ errorMap[errorCode] }}
                    </span>
                    <span v-else>
                      Unhandled error processing request
                    </span>

              </v-col>
            </v-row>

          </v-alert>

        </v-card-text>
      </base-material-card>

    </v-container>
  </v-main>

</template>

<script>

import api from '@/services/api'

export default {
  name: "CoreLoginView",
  created() {
    this.errorCode = this.$route.query.errorCode
  },
  components: {
  },
  methods: {},
  data() {
    return {
      errorCode: null,
      errorMessage: null,
      errorMap: api.getErrorMap()
    }
  }
};

</script>
